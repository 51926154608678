import React from 'react'
import Link from 'next/link'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'
import { WithRouterProps } from 'next/dist/client/with-router'
import { EntityChangeRequest } from '@mpe/api-client/models/change-requests/entity-change-request'
import { IPagedEntityChangeRequestCollection } from '@mpe/api-client/types'

import Loader from '../common/Loader'

import { IRootState } from '../../store'
import { IEntityChangeRequestsState } from './store/state'

import { formatDate } from '../../utils/dateHelper'
import { fetchEntityChangeRequests } from './store/actions'

interface Props extends WithRouterProps {
  collection: IEntityChangeRequestsState['collection']
  fetchEntityChangeRequests: (state: string, orderBy: string, orderDirection: string) => Promise<IPagedEntityChangeRequestCollection>
}

interface State {
}

class DashboardList extends React.Component<Props, State> {
  componentDidMount() {
    this.props.fetchEntityChangeRequests('completed', 'completedAt', 'DESC')
  }

  render() {
    if ('loaded' !== this.props.collection.status) {
      return <Loader wrapperClass="spinner-large" label="Módosítás kérések betöltése..."/>
    }

    const albumChangeRequests = this.props.collection.data.items?.filter(
      (changeRequest: EntityChangeRequest<any>) => changeRequest && 'AlbumChangeRequest' === changeRequest.type,
    )
    const notAlbumChangeRequests = this.props.collection.data.items
      ?.filter((changeRequest: EntityChangeRequest<any>) => changeRequest && !['AlbumChangeRequest', 'ImageChangeRequest', 'AudioChangeRequest'].includes(changeRequest.type),)

    return (
      <div className="entity-change-request-list">
        <h1 className="h2 mt-3">Jóváhagyásra váró módosítás kérések</h1>

        <div className="row">
          <div className="col col-md-6">
            <h2 className="h6 mt-3">Entitások</h2>

            {notAlbumChangeRequests?.length > 1 && (
              <Link href={`/entity-change-requests`}>
                <a className="btn btn-primary">Tömeges jóváhagyás</a>
              </Link>
            )}

            {this.renderTable(notAlbumChangeRequests)}
          </div>

          <div className="col col-md-6">
            <h2 className="h6 mt-3">Média albumok</h2>

            {this.renderTable(albumChangeRequests)}
          </div>
        </div>

        <hr className="my-5"/>
      </div>
    )
  }

  renderTable(items: EntityChangeRequest<any>[]) {
    if (!items?.length) {
      return (
        <p>Nincs jóváhagyásra váró entitás.</p>
      )
    }

    return (
      <div className="entity-change-request-list__scrollable">
        <table className="table table-striped table-hover mt-3">
          <tbody>
          {items.map((item: EntityChangeRequest<any>) => {
            return (
              <tr key={item.id}>
                <td>
                  {item.entity && (
                    <Link href={`/entities/${item.entity.id}/view`}>
                      {item.name}
                    </Link>
                  )}
                  {!item.entity && (
                    item.name
                  )}
                </td>
                <td>{formatDate(item.completedAt, 'YYYY. MM. DD. HH:mm') ?? '-'}</td>
                <td>{item.completedBy?.email ?? '-'}</td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  collection: state.entityChangeRequests.collection,
})

const mapDispatchToProps = {
  fetchEntityChangeRequests,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardList))
