import { Dispatch } from 'redux'
import apiClient from '@mpe/api-client'
import { EntityChangeRequest } from '@mpe/api-client/models/change-requests/entity-change-request'
import {
  DELETE_ENTITY_CHANGE_REQUEST_IMAGE_ERROR,
  DELETE_ENTITY_CHANGE_REQUEST_IMAGE_REQUEST,
  DELETE_ENTITY_CHANGE_REQUEST_IMAGE_SUCCESS,
  FETCH_CONNECTION_HISTORY_ERROR,
  FETCH_CONNECTION_HISTORY_REQUEST,
  FETCH_CONNECTION_HISTORY_SUCCESS,
  FETCH_ENTITY_CHANGE_REQUEST_HISTORY_ERROR,
  FETCH_ENTITY_CHANGE_REQUEST_HISTORY_REQUEST,
  FETCH_ENTITY_CHANGE_REQUEST_HISTORY_SUCCESS,
  FETCH_ENTITY_CHANGE_REQUESTS_ERROR,
  FETCH_ENTITY_CHANGE_REQUESTS_REQUEST,
  FETCH_ENTITY_CHANGE_REQUESTS_SUCCESS,
  RESET_LOADED_ENTITY_CHANGE_REQUEST,
  UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_ERROR,
  UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_REQUEST,
  UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_SUCCESS,
} from './types'

export const fetchEntityChangeRequests = (state: string, orderBy: string = 'id', orderDirection: string = 'ASC', limit: number = 50) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_ENTITY_CHANGE_REQUESTS_REQUEST })

  try {
    const pagedCollection = await apiClient.getPaginatedEntityChangeRequests({
      state: state ?? null,
      'itemsPerPage': String(limit),
      'exists[rejectedAt]': 'false',
      'exists[approvedAt]': 'false',
      [`order[${orderBy}]`]: orderDirection,
    })

    dispatch({ type: FETCH_ENTITY_CHANGE_REQUESTS_SUCCESS, payload: pagedCollection })

    return pagedCollection

  } catch (err) {
    dispatch({ type: FETCH_ENTITY_CHANGE_REQUESTS_ERROR })
  }
}

export const fetchEntityChangeRequestHistory = (entity: string, page?: string) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_ENTITY_CHANGE_REQUEST_HISTORY_REQUEST })

  try {
    const pagedCollection = await apiClient.getPaginatedEntityChangeRequests({
      'entity': entity,
      'itemsPerPage': '30',
      'page': page ?? null,
      'exists[approvedAt]': 'true',
      [`order[id]`]: 'DESC',
    })

    dispatch({ type: FETCH_ENTITY_CHANGE_REQUEST_HISTORY_SUCCESS, payload: pagedCollection })

    return pagedCollection

  } catch (err) {
    dispatch({ type: FETCH_ENTITY_CHANGE_REQUEST_HISTORY_ERROR })
  }
}

export const fetchConnectionHistory = (entity: string, page?: string) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_CONNECTION_HISTORY_REQUEST })

  try {
    const pagedCollection = await apiClient.getPaginatedConnectionHistoriesRequests({
      'entity': entity,
      'itemsPerPage': '30',
      'page': page ?? null,
      'order[id]': 'DESC',
    })

    dispatch({ type: FETCH_CONNECTION_HISTORY_SUCCESS, payload: pagedCollection })

    return pagedCollection

  } catch (err) {
    dispatch({ type: FETCH_CONNECTION_HISTORY_ERROR })
  }
}

export const updateEntityChangeRequestImage = (entity: EntityChangeRequest<any>, payload: any) => async (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_REQUEST })

  try {
    const formData = new FormData()
    Object.keys(payload).forEach((key: string) => formData.append(key, payload[key]))

    const item = await apiClient.updateEntityChangeRequestImage(`/entity_change_requests/${entity.id}/image`, formData)

    if (!item) {
      throw Error('Invalid response')
    }

    dispatch({ type: UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_SUCCESS, item })

    return item

  } catch (err) {
    console.log(err)
    dispatch({ type: UPDATE_ENTITY_CHANGE_REQUEST_IMAGE_ERROR })
  }
}

export const deleteEntityChangeRequestImage = (entity: EntityChangeRequest<any>, imageType: string) => async (dispatch: Dispatch) => {
  dispatch({ type: DELETE_ENTITY_CHANGE_REQUEST_IMAGE_REQUEST })

  try {
    const response = await apiClient.deleteByIri(`/entity_change_requests/${entity.id}/image/${imageType}`)

    if (!response) {
      throw Error('Invalid response')
    }

    dispatch({ type: DELETE_ENTITY_CHANGE_REQUEST_IMAGE_SUCCESS, imageType: imageType })

    return true

  } catch (err) {
    dispatch({ type: DELETE_ENTITY_CHANGE_REQUEST_IMAGE_ERROR })
  }

  return false
}

export const resetLoadedEntityChangeRequest = () => async (dispatch: Dispatch) => {
  dispatch({ type: RESET_LOADED_ENTITY_CHANGE_REQUEST })
}
