import React from 'react'
import { ChangelogDataItem } from '../../changelog'

interface Props {
  changelogData: ChangelogDataItem[]
}

interface State {
  opened: boolean
}

class Changelog extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {opened: false};
  }

  render() {
    const visibleItems = [];
    const hiddenItems = [];

    this.props.changelogData.forEach((changelogDataItem: ChangelogDataItem, key: number) => {
      if (key < 5) {
        visibleItems.push(changelogDataItem);
        return;
      }

      hiddenItems.push(changelogDataItem);
    });

    return (
      <div className="col-12 col-lg-6 my-3">
        <h1 className="h2">Changelog</h1>

        {visibleItems.map((changelogDataItem: ChangelogDataItem, key: number) => (
          <div className="mt-3" key={key}>
            <h2 className="h6">{changelogDataItem.date}</h2>
            <ul>
              {changelogDataItem.items.map((value: string, key2: number) => <li key={key2}>{value}</li>)}
            </ul>
          </div>
        ))}
        <a className="btn btn-link" onClick={() => this.setState({opened: !this.state.opened})} >Tovább</a>
        <div className={this.state.opened ? '' : 'collapse'} id="changeLogCollapse">
          {hiddenItems.map((changelogDataItem: ChangelogDataItem, key: number) => (
            <div className="mt-3" key={key}>
              <h2 className="h6">{changelogDataItem.date}</h2>
              <ul>
                {changelogDataItem.items.map((value: string, key2: number) => <li key={key2}>{value}</li>)}
              </ul>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Changelog
