const changelogData: ChangelogDataItem[] = [
  {
    date: '2024.02.12.',
    items: [
      'Zene album feltöltés hozzáadása',
      'Blog és kapcsolódó linkek szerkesztése',
    ]
  },
  {
    date: '2023.12.14.',
    items: [
      'Módosítási kérés kapcsolatainak ellenőrzése kódszinten jóváhagyás előtt',
      'Látványosabb megerősítés kérés entitás törlése előtt',
      'Javítás: entitás kapcsolatainak a száma frontend-en',
    ]
  },
  {
    date: '2023.10.30.',
    items: [
      'Lejátszási listák egységesítése',
    ]
  },
  {
    date: '2023.10.16.',
    items: [
      'Lapozható kapcsolatok a módosítási kérések listájában',
      'Asyc módosítási kérés létrehozás',
      'Asyc módosítási kérés elfogadás',
      'Asyc entitás duplikálás',
      'Entitás összefésülés kolátozása',
    ]
  },
  {
    date: '2023.10.05.',
    items: [
      'Videó lejátszás: eredti feltöltés használata, ha kifü nem elérhető',
    ]
  },
  {
    date: '2023.06.05.',
    items: [
      'Audió feltötlés javítása'
    ]
  },
  {
    date: '2023.05.23.',
    items: [
      'Hivatkozások szerkesztésének módosítása',
      'Forrás hivatkozások feltöltése',
      'Videó konvertálás javítása'
    ]
  },
  {
    date: '2023.05.04.',
    items: [
      'Hivatkozások szerkesztés',
    ]
  },
  {
    date: '2023.05.02.',
    items: [
      'Entitás kereső javítások',
      'Frontend: index widget',
      'Frontend: chatGPT megjelenítés javítása',
    ]
  },
  {
    date: '2023.04.14.',
    items: [
      'Entitás duplikálás funkció',
      'Tooltip a személyek entitásokhoz',
      'Eszköztár gombjainak átalakítása az entitás oldalon',
    ]
  },
  {
    date: '2023.03.17.',
    items: [
      'ChatGPT bekötése a frontend-en',
    ]
  },
  {
    date: '2023.03.07.',
    items: [
      'Publikus lejárszási listák a frontend-en',
      'Szabad forrás megjelölés az entitás képeknél',
    ]
  },
  {
    date: '2023.02.16.',
    items: [
      'Új nyitóoldal a frontend-en',
      'Nyitóoldalhoz szerkesztő felület a backend-en',
    ]
  },
  {
    date: '2023.01.30.',
    items: [
      'Beágyazó link szerkesztő videókhoz'
    ]
  },
  {
    date: '2023.01.26.',
    items: [
      'Javítás: admin oldali belépés'
    ]
  },
  {
    date: '2022.12.15.',
    items: [
      'Javítás: entitás mentése szerző beállítással'
    ]
  },
  {
    date: '2022.12.14.',
    items: [
      'Vidók listája rendezhető "Dátum" szerint',
      'Javítás: compass filmből egy szám áttöltése esetén is kapcsoljuk a videóhoz a film készítőit',
      'Javítás: szerepkörök compass film import során'
    ]
  },
  {
    date: '2022.12.09.',
    items: [
      '"Szerző" tulajdonság hozzáadása az entitásokhoz',
      'Frontend: Frissítés dátumának módosítása, szerző megjelenítése az entitások leírása után',
    ],
  },
  {
    date: '2022.12.05.',
    items: [
      '"Zenekar" entitás átnevezése "Előadó"-ra',
    ],
  },
  {
    date: '2022.11.21.',
    items: [
      'Dalszerző szerepkör hozzáadás',
      'Összdarabszám megjelenítése az entitások listájában',
      'WYSIWYG szerkesztő az entitások leírásaihoz'
    ],
  },
  {
    date: '2022.10.21.',
    items: [
      'Zeneszöveg blokk hozzáadása',
    ],
  },
  {
    date: '2022.10.10.',
    items: [
      'Zenekar prioritások: opciók és működés módosítása',
    ],
  },
  {
    date: '2022.10.06.',
    items: [
      'Felhasználók kezelése',
      'Több (max 3) általános szerepkör a személyek adatlapján',
    ],
  },
  {
    date: '2022.09.29.',
    items: [
      'Zenekar prioritások',
    ],
  },
  {
    date: '2022.09.07.',
    items: [
      'Gyerekzenekarok címke',
    ],
  },
  {
    date: '2022.08.19.',
    items: [
      'Interjú tulajdonság hozzáadása az audió és videó entitásokhoz',
      'Szerkesztői megjegyzés mező hozzáadása',
    ],
  },
  {
    date: '2022.07.19.',
    items: [
      'Audió entitásoknál új oszlopok: előadó, album és dal cím',
    ],
  },
  {
    date: '2022.06.17.',
    items: [
      'Kapcsolat hozzáadásánál szűrő az entitás típusára',
      'Javítás: Média album személyek összekötése',
      'Javítás: audió fájlok dátumának módosítása',
    ],
  },
  {
    date: '2022.05.26.',
    items: [
      'Módosítási kérések historikus adatainak megtekintése',
      'Kapcsolatok módosítások historikus adatainak megtekintése',
    ],
  },
  {
    date: '2022.05.24.',
    items: [
      'Entitások képeinek beállítása fájl feltöltés vagy URL-ből'
    ],
  },
  {
    date: '2022.05.16.',
    items: [
      'Javítás: duplikált kapcsolatok lehetőségének megszüntetése'
    ],
  },
  {
    date: '2022.05.03.',
    items: [
      'Audió fájlok fájlméretének kijelzése a listában',
      'Javítás: audió fájlok kapcsolásánál lévő hiba javítása',
      'Javítás: a38 galéria import hibának javítása'
    ],
  },
  {
    date: '2022.05.02.',
    items: [
      'Javítás: keresési szöveg megtartása lapozáskor'
    ],
  },
  {
    date: '2022.04.22.',
    items: [
      'Adminisztrátori menü létrehozása'
    ],
  },
  {
    date: '2022.04.21.',
    items: [
      'Javítás: kapcsolatok számának hibás számolása'
    ],
  },
  {
    date: '2022.04.11.',
    items: [
      'Javítás: videó szegmenseknél a nagy ékezetes karakterek keresése',
    ],
  },
  {
    date: '2022.04.06.',
    items: [
      'Publikálás megszüntetésének lehetősége az entitás nyitott oldalán',
      'Javítás: képkivágás hiba Portrait méretben',
    ],
  },
  {
    date: '2022.03.29.',
    items: [
      'Javítás: kép ikonok MTVA képek kezelése a listában',
    ],
  },
  {
    date: '2022.03.25.',
    items: [
      'MTVA / Fortepan képek importálása média albumoknál',
    ],
  },
  {
    date: '2022.03.04.',
    items: [
      'Videó iktatószám mentése az adott joggal rendelkező felhasználók számára',
    ],
  },
  {
    date: '2022.03.01.',
    items: [
      'Összefésülés funkció ugyanolyan típusú entitásokhoz',
    ],
  },
  {
    date: '2022.02.11.',
    items: [
      'Fortepan kép beállítási lehetőség az entitások cover / portrait / stb poziciójába',
    ],
  },
  {
    date: '2022.02.03.',
    items: [
      'MTVA kép beállítási lehetőség az entitások cover / portrait / stb poziciójába',
    ],
  },
  {
    date: '2022.01.11.',
    items: [
      'A személy és a média album kapcsolásakor jelentkező hiba javítása',
    ],
  },
  {
    date: '2022.01.04.',
    items: [
      'Jóváhagyási hiba javítása',
    ],
  },
  {
    date: '2021.12.20.',
    items: [
      'Dátum megjelenítése a compass videó keresőben',
    ],
  },
  {
    date: '2021.12.17.',
    items: [
      'Hiba javítása: Audió és kép entitások szerkesztése esetén helytelenül automatikusan beküldött állapotba került',
    ],
  },
  {
    date: '2021.12.15.',
    items: [
      'Dátum megjelenítése a lista nézetekben',
    ],
  },
  {
    date: '2021.11.21.',
    items: [
      'Média album import funkció: a38.hu galéria képeinek áttöltése az MPE rendszerébe',
    ],
  },
  {
    date: '2021.11.03.',
    items: [
      'Jóváhagyandó módosítási kérések listájában a felhasználó és a dátum megjelenítése',
    ],
  },
  {
    date: '2021.10.20.',
    items: [
      'Entitások szerkesztése szerkesztői jóváhagyással',
    ],
  },
  {
    date: '2021.08.18.',
    items: [
      'Képszerkesztő hiba javítása (teljes szélességű kivágásnál jelentkező probléma)',
    ],
  },
  {
    date: '2021.06.30.',
    items: [
      'Videó editálás hiba javítása',
    ],
  },
  {
    date: '2021.06.29.',
    items: [
      'Média albumba való feltöltéskor opcionális kapcsolat másolás',
    ],
  },
  {
    date: '2021.06.23.',
    items: [
      'Gráf nézet került az entitás szerkesztő aljára',
    ],
  },
  {
    date: '2021.06.18.',
    items: [
      'Videólejátszó a videók aloldalra, ugorható szegmensekkel',
    ],
  },
  {
    date: '2021.06.02.',
    items: [
      'Média elemek (képek, audió, videó) kötésének javítása',
    ],
  },
  {
    date: '2021.05.27.',
    items: [
      'Új Média forrás attribútum Audio, Kép és Videó esetében',
    ],
  },
  {
    date: '2021.05.26.',
    items: [
      'Dátum miatti entitás létrehozás hiba javítása',
    ],
  },
  {
    date: '2021.05.18.',
    items: [
      'Új díjak entitástípus',
      'Popup szerkesztő méretének javítása kis felbontáson',
    ],
  },
  {
    date: '2021.05.17.',
    items: [
      'Dal entitások új "Kiadás dátuma" attribútum',
    ],
  },
  {
    date: '2021.05.14.',
    items: [
      'Változások követése a dashboardon',
      'Navigáció került az oldalak tetejére',
      'Törlés gomb hozzáadása a nyitott nézeten',
      'Kapcsolatok bal és jobb oldalának entitás szerinti elnevezése',
    ],
  },
  {
    date: '2021.05.13.',
    items: [
      'A felesleges szóközök eltávolítása a formokon bejövő adatokon',
      'Keresősáv hozzáadása a listák tetejére',
      'Audio és Kép listáknál a hozzáadás menetének feltüntetése',
      'Entitásokhoz tartozó képszerkesztő',
    ],
  },
]

export interface ChangelogDataItem {
    date: string
    items: string[]
}

export default changelogData
