import React from "react";
import apiClient from "@mpe/api-client";
import Loader from "../common/Loader";
import {humanFilesize} from "../../utils/filesizeHelper";

interface Props {
}

interface State {
  loading: boolean,
  list: any[],
  uploadQueue?: any,
  converterQueue?: any,
}

const REFRESH_INTERVAL_SEC = 60

class List extends React.Component<Props, State> {
  timeoutId: number | null = null

  constructor(props: Readonly<Props> | Props) {
    super(props)

    this.state = {
      loading: false,
      list: [],
      uploadQueue: null,
      converterQueue: null,
    }
  }

  componentDidMount() {
    this.updateList()
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId)
    }
  }

  render() {
    return (
      <div className="col-12 col-lg-6 my-3 video-converting">
        <div className="d-flex justify-content-between">
          <h1 className="h2">Videó konvertálás</h1>
          {this.state.loading && <Loader className="spinner-border text-secondary spinner-border-sm" wrapperClass="align-content-center" /> }
        </div>
        { this.state.list.length ? <table className="table table-striped">
            <thead><tr>
              <th>Videó</th>
              <th title="Áttöltés S3 serverre" className="text-center">S3</th>
              <th title="Konvertálás állapota">Konvertálás</th>
              <th title="Audió változat" className="text-center">Audió</th>
            </tr></thead>
            <tbody>
          {this.state.list.map((entry:any, i:number) => {
            const entityLink = entry.editorState !== "readonly" ? `/entities/${entry.id}/edit` : null;
            let icon = null;
            if (this.state.uploadQueue && this.state.uploadQueue.id === entry.id) {
              icon = <span title='Áttöltés folyamatban' className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
            }
            if (this.state.converterQueue && this.state.converterQueue.id === entry.id) {
              icon = <span title='Konvertálás folyamatban' className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
            }

            return <tr key={i}>
              <td className="entity-name">
                <a title={entry.name} className="d-inline-block mw-100 text-truncate" href={entityLink}>
                  {icon}
                  {entry.name}
                </a>
              </td>
              <td className="text-center">
                {this.getFileUploadStatus(entry.status?.upload)}
              </td>
              <td className="d-flex align-items-center">
                {this.getFileDownloadStatus(entry.status?.converter, 'd-inline-block ml-2')}
                {this.getPreviewImageStatus(entry.status?.converter, 'd-inline-block ml-2')}
                {this.getVideoConverting(entry.status?.converter, 'd-inline-block flex-grow-1 ml-2')}
              </td>
              <td className="text-center">
                {this.getAudioStatus(entry.status?.audio)}
              </td>
            </tr>;
          })}
          </tbody></table>
        : <div>
            <p className="text-muted py-5 text-center small">Nincs convertálás alatt álló videó</p>
          </div>}
        <div></div>
      </div>
    )
  }

  async updateList() {
    this.setState({loading: true})

    const response = await apiClient.getConvertingVideos();
    this.setState({loading: false});

    if (response?.videos && response.videos.length > 0) {
      const list = (response.videos as []).map((item: any) => {
        item.status = JSON.parse(item.status)

        return item;
      })

      this.setState({
        list,
        uploadQueue: response.upload_queue,
        converterQueue: response.converter_queue,
      });

      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId);
      }
      this.timeoutId = window.setTimeout(() => this.updateList(), REFRESH_INTERVAL_SEC * 1000);
    }
  }

  private getFileUploadStatus(status?: any, spanClass?: string) {
    if (!status) {
      return <span className={spanClass} title="Fájl áttöltés: ismeretlen"><i className={`fa fa-cloud-upload-alt text-muted`}></i></span>
    }

    const isComplete = status.upload_status === 'completed';

    return <span className={spanClass} title={`Fájl áttöltés [${ humanFilesize(status.uploaded_size) }]: ${isComplete ? 'kész' : Math.round(status.uploaded_size/status.file_size*100)+'%'}`}>
      <i className={`fa fa-cloud-upload-alt ${isComplete ? 'text-success' : 'text-primary'}`}></i>
    </span>
  }

  private getFileDownloadStatus(status?: any, spanClass?: string) {
    if (!status || !status.file_status) {
      return <span className={spanClass} title="Fájl letöltés: ismeretlen"><i className={`fa fa-file-download text-muted`}></i></span>
    }

    const isComplete = status.file_status === 'completed';

    return <span className={spanClass} title={`Fájl letöltés: ${isComplete ? 'kész' : 'folyamatban'}`}>
      <i className={`fa fa-file-download ${isComplete ? 'text-success' : 'text-danger'}`}></i>
    </span>
  }

  private getPreviewImageStatus(status?: any, spanClass?: string) {
    if (!status || !status.preview_status) {
      return <span className={spanClass} title="Előnézeti képek: ismeretlen"><i className={`fa fa-images text-muted`}></i></span>
    }

    const isComplete = status.preview_status === 'completed';

    return <span className={spanClass} title={`Előnézeti képek: ${isComplete ? 'kész' : 'folyamatban'}`}>
      <i className={`fa fa-images ${isComplete ? 'text-success' : 'text-danger'}`}></i>
    </span>
  }

  private getAudioStatus(status?: any, spanClass?: string) {
    if (!status) {
      return <span className={spanClass} title="Audió: ismeretlen"><i className={`fa fa-music text-muted`}></i></span>
    }

    const isComplete = status !== null;

    return <span className={spanClass} title={`Audió: ${isComplete ? 'kész' : 'nem található'}`}>
      <i className={`fa fa-music ${isComplete ? 'text-success' : 'text-danger'}`}></i>
    </span>
  }

  private getVideoConverting(status?: any, barClass?: string) {
    if (!status || !status.video_status) {
      return <div className={`${barClass}`}>
        <div title="Videók: ismeretlen" className="progress">
          <div className="progress-bar bg-secondary" role="progressbar" style={{width: '100%'}}>Ismeretlen</div>
        </div>
      </div>
    }

    const colors = [
      'bg-success',
      'bg-info',
      'bg-warning',
      'bg-danger',
    ]
    const videoStatuses = Object.values(status.video_status)

    return <div className={`${barClass}`}>
      <div className="progress">
        {videoStatuses.map((item:any, i:number) => {
          return <div key={i}
                      title={`Méret ${item.type}: ${item.percent}%`}
                      className={`progress-bar ${colors[(i+1) % colors.length]}`}
                      role="progressbar"
                      style={{width: item.percent+'%'}}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      aria-valuenow={item.percent}
          >{`${item.percent}%`}</div>
        })}
      </div>
    </div>
  }
}

export default List
