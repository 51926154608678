import React from "react";
import Head from 'next/head'
import Changelog from '../components/changelog/Changelog'
import DashboardList from '../components/entity-change-requests/DashboardList'
import List from '../components/video-converter/List'
import changelogData from '../changelog'
import store from '../store'

function Dashboard() {
  const state = store.getState()

  return (
    <>
      <Head>
        <title>MPE</title>
      </Head>

      <div className="container-fluid">
        {state.auth.user.isApprover() && <DashboardList/>}
        <div className="row">
          <List />
          <Changelog changelogData={changelogData}/>
        </div>
      </div>
    </>
  )
}

export default Dashboard
