import moment from 'moment'

export const formatDate = (value: string | null, format: string = 'YYYY-MM-DD') => {
  if (null === value) {
    return null
  }

  try {
    const date = moment(value).format(format) ?? ''

    if ('Invalid date' === date) {
      return value
    }

    return date

  } catch (err) {
    console.log(err)
  }

  return value
}

export const getISOFormat = (date: Date): string => {
  return moment(date).toISOString(true);
}

export const getFromISOString = (date: string, format: string = 'YYYY-MM-DD'): string => {
  return moment(date).format(format);
}
